<template>
    <div class="notice alarm detail mw1260">
        <div class="title-wrap w1040a">
            <div class="title">
                알림
            </div>
        </div>
        <div class="content-wrap">
            <div class="title">
                {{alarmDetail.psl_title}}
            </div>
            <div class="info">
                <div class="date">{{reformDate(alarmDetail.created_at)}}</div>
                <div class="content">
                    {{alarmDetail.psl_content}}
                </div>
                <div class="d-info">
                    {{returnContentTitleType()}}
                    <div class="text cursor" @click="checkSecret()">{{alarmDetail.content_title}}</div>
                </div>
                <button class="delete-btn" @click="deleteConfrim(alarmKey)">알림삭제</button>
            </div>
        </div>
        <div class="btn-wrap">
            <button class="full_btn list_btn" @click="$router.back()">목록</button>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import VuxAlert from "@/mixins/vux-alert";

    export default {
        name: "AlarmDetailLayout",
        mixins: [VuxAlert],
        inject: ['alarmDelete'],
        props: {},
        data() {
            return {
                alarmKey: Number(this.$route.params.psl_idx),
                alarmDetail: {},
                contentDetail: {},
                tryCnt: 0,
                contentObj: {},
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
                alarmList: state => state.alarm.list,
            }),
        },
        mounted() {
            this.getDetail();
        },
        methods: {
            openDetailErrorAlert() {
                this.createAlert({
                    title: '',
                    content: '상세를 불러올 수 없습니다. 잠시후 다시 시도해주세요',
                    btnText: '확인',
                    hide: () => {
                        this.$router.back();
                    }
                });
            },
            getDetail() {
                this.tryCnt = this.tryCnt + 1;
                if (this.tryCnt > 5) {
                    this.openDetailErrorAlert();
                    return;
                }
                let alarmIndex = this.alarmList.findIndex(alarm => alarm.psl_idx === this.alarmKey);
                if (alarmIndex < 0) {
                    setTimeout(() => {
                        this.getDetail();
                    }, 500)
                } else {
                    this.alarmDetail = this.alarmList[alarmIndex];
                    this.getContent();
                }
            },
            returnContentTitleType() {
                let alarmType = this.alarmDetail.psl_method;
                switch (alarmType) {
                    case 'letter':
                    case 'free':
                    case 'mentor':
                    case 'notice':
                        return '글제목 : ';
                    case 'member':
                        return "아이디 : ";
                    case 'activity':
                        return '글제목 : ';

                }
            },
            getContent() {
                let alarmType = this.alarmDetail.psl_method;
                let contentKey = this.alarmDetail.psl_code;
                console.log(alarmType, contentKey)
                switch (alarmType) {
                    case 'letter':
                        this.getLetter(contentKey)
                        break;
                    case 'free':
                        this.getCommu(contentKey);
                        break;
                    case 'member':
                        this.getMember(contentKey);
                        break;
                    case 'mentor':
                        this.getMentor(contentKey);
                        break;
                    case 'notice':
                        this.getNotice(contentKey);
                        break;
                    case 'activity':
                        this.getAmidan(contentKey);
                        break;
                }
            },
            getMember(contentKey) {
                let params = {
                    m_type: 'normal',
                    ip: 0,
                    ot: 1,
                    no: contentKey,
                }
                try {
                    this.$api.$member.getMemberList(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type != 1) {
                            this.openDetailErrorAlert();
                            return false;
                        }
                        this.alarmDetail.content_title = res.List[0].mb_id;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })
                } catch (e) {
                    console.log(e);
                }
            },
            getLetter(contentKey) {
                let params = {
                    wr_type: 'post',
                    ip: 0,
                    id: contentKey
                };
                try {
                    this.$api.$board.getLetter(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type = !1) {
                            this.openDetailErrorAlert();
                            return;
                        }
                        this.alarmDetail.content_title = res.List[0].wr_subject;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            getCommu(contentKey) {
                let params = {
                    wr_type: 'post',
                    ip: 0,
                    id: contentKey
                };
                try {
                    this.$api.$board.getCommu(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type = !1) {
                            this.openDetailErrorAlert();
                            return;
                        }
                        this.alarmDetail.content_title = res.List[0].wr_subject;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            getMentor(contentKey) {
                let params = {
                    wr_type: 'post',
                    ip: 0,
                    id: contentKey
                };
                try {
                    this.$api.$board.getMentorCommu(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type = !1) {
                            this.openDetailErrorAlert();
                            return;
                        }
                        this.alarmDetail.content_title = res.List[0].wr_subject;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            getNotice(contentKey) {
                let params = {
                    wr_type: 'post',
                    ip: 0,
                    id: contentKey
                }
                try {
                    this.$api.$board.getNotice(params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type != 1) {
                            this.openDetailErrorAlert();
                            return;
                        }
                        this.alarmDetail.content_title = res.List[0].wr_subject;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })

                }catch (e) {
                    console.log(e);
                }
            },
            getAmidan(contentKey) {
                let params = {
                    wr_type: 'post',
                    ip: 0,
                    id: contentKey
                }
                try {
                    this.$api.$board.getAmidan(params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type != 1) {
                            this.openDetailErrorAlert();
                            return;
                        }
                        this.alarmDetail.content_title = res.List[0].wr_subject;
                        this.contentObj = res.List[0];
                        this.$forceUpdate();
                    })

                }catch (e) {
                    console.log(e);
                }
            },
            deleteConfrim(psl_idx = null) {
                this.createConfirm({
                    title: '',
                    content: '정말 삭제하시겠습니까?',
                    confirmText: '확인',
                    cancelText: '취소',
                    confirm: () => {
                        this.alarmDelete(psl_idx);
                    }
                })
            },
            reformDate(date) {
                if (!date) {
                    return ''
                }
                return this.$moment(date).format('YYYY.MM.DD HH:mm')
            },
            checkSecret() {
                if (this.alarmDetail.psl_method === 'free' && this.UserInfo.mb_type === 1 && this.contentObj.secret != null) {
                    this.createConfirm({
                        title: '',
                        content: `아이에겐 심각하고 진지한, 심지어는 부끄럽고 창피한 글 일수도 있습니다.<br/>진지하게 답변해주시기 바라며 해당 내용을 어떠한 곳에도 공유/게시하지 않을 것을 약속해주세요.<br>위반시 불이익을 당할 수 있습니다.`,
                        confirmText: '확인',
                        cancelText: '취소',
                        confirm: () => {
                            this.goDetail();
                        }
                    })
                } else {
                    this.goDetail();
                }
            },
            goDetail() {
                let url = '';
                let alarmType = this.alarmDetail.psl_method;
                let contentKey = this.alarmDetail.psl_code;
                let memberType = this.UserInfo.mb_type;
                if (alarmType === 'free') {
                    url = '/commu/';
                } else if (alarmType === 'letter') {
                    url = '/letter/';
                }else if(alarmType === 'mentor') {
                    url = '/mentor/'
                }else if(alarmType === 'notice') {
                    url = '/notice/'
                }else if(alarmType === 'activity') {
                    url = '/amidan/'
                }
                if (url != '' && contentKey) {
                    this.$router.push(`${url}${contentKey}`);
                }

            },
        },
    }
</script>
<style scoped>

</style>
